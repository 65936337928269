import React from "react";
import MainButton from "./../components/MainButton";
type Props = {
  label: React.ReactNode;
  mode?: "dark" | "light" | "main";
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  disabled?: boolean;
  wrapperStyle?: React.CSSProperties;
  className?: string;
};
const NTButton = (props: Props) => {
  return (
    <>
      <MainButton {...props}></MainButton>
    </>
  );
};

export default NTButton;
