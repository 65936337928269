import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "common/api/BaseQuery";
import {
  CouponCollectionJson,
  FilterCoupon,
  FilterPromotion,
  PromotionCollectionJson,
} from "../types/coupon.type";
import { Jwt } from "common/types/Api";

export const couponApi = createApi({
  reducerPath: "couponApi",
  baseQuery: baseQuery(),
  tagTypes: ["CouponList"],
  endpoints: (builder) => ({
    getCouponList: builder.query<CouponCollectionJson, FilterCoupon>({
      query: (params: FilterCoupon & Jwt) => ({
        url: "customers/coupon",
        params: {
          ...params,
          // __jwtAuthorization: "be9ec9f617a5f427c2b96a0a352cc8a1",
        },
      }),
      keepUnusedDataFor: 3,
      transformResponse: (response: CouponCollectionJson, _) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
    }),
    getPromotionList: builder.query<PromotionCollectionJson, FilterCoupon>({
      query: (params: FilterPromotion & Jwt) => ({
        url: "/promotions/public",
        params: {
          type: params.type,
          // __jwtAuthorization: "be9ec9f617a5f427c2b96a0a352cc8a1",
        },
      }),
      keepUnusedDataFor: 3,
      transformResponse: (response: PromotionCollectionJson, _) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
    }),
  }),
});

export const { useGetCouponListQuery, useGetPromotionListQuery } = couponApi;
