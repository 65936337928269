import { useState, useEffect } from "react";
import i18n from "i18n";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider } from "antd";

/////////////////////////////////////////////
// Ant-design provided locales
import vi_VN from "antd/lib/locale/vi_VN";
import en_US from "antd/lib/locale/en_US";

/////////////////////////////////////////////
// Moment.js provided locales
//en-US is default by moment, so that not need import
import "moment/locale/vi";
import moment from "moment";

const AppWithLocale = ({ children }: { children: React.ReactNode }) => {
  const [antdLocale, setAntdLocale] = useState(vi_VN);
  useEffect(() => {
    //update main locale
    moment.locale("vi");
    switch (antdLocale) {
      default:
        setAntdLocale(vi_VN);
    }
  }, [antdLocale]);
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>
    </I18nextProvider>
  );
};

export default AppWithLocale;
