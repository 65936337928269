import React, { useEffect, useState } from "react";
import styles from "./currentRated.module.css";
import { useGetRatePublicQuery } from "features/comment/service/comment.service";
import RateStarDetail from "../commentFilter/RateStarDetail";

type Props = {
	item_type: string;
	item_id: string;
};
const CurrentRated = ({ item_type, item_id }: Props) => {
	// const star = useAppSelector(selectRate);
	// const countRate = useAppSelector(selectCountRate);
	const [star, setStar] = useState(0);
	const [countRate, setCountRate] = useState(0);

	const { data } = useGetRatePublicQuery({
		item_id: item_id,
		item_type: item_type,
	});

	useEffect(() => {
		if (data) {
			setStar(data.rate);
			setCountRate(data.count_rate);
		}
	}, [data]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.listRate}>
				<RateStarDetail stars={star} size="desktop" />
			</div>
			<span id="nperp_countRate" className={styles.countRate}>
				{countRate} <span className={styles.countRate_title}>đánh giá</span>
			</span>
		</div>
	);
};

export default CurrentRated;
