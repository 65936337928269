import { baseQuery } from "common/api/BaseQuery";
import {
  ForgotPasswordJson,
  ForgotPasswordPostJson,
  LoginPostJson,
  LoginJson,
  ResetPasswordPostJson,
  SignupJson,
  SignupPostJson,
  VerifyPostJson,
  VerifyJson,
  ResendPostJson,
  SenderJson,
  CheckSenderJson,
  SettingJson,
  loginACPostJson,
} from "../types/auth.type";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import Setting from "setting";
import Helper from "common/utils/helper";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery(),
  tagTypes: [
    "CommentPublic",
    "CommentLogin",
    "ReviewLogin",
    "ReviewPublic",
    "LikedLogin",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginJson, LoginPostJson>({
      query: (body) => ({
        url: "users/customer/login",
        method: "post",
        body: {
          ...body,
          platform: 1,
          hostname: Setting.REACT_APP_BASE_URL_HOST,
          version: "1.0.0",
        },
        keepalive: false,
      }),

      invalidatesTags: (result, error, body) => [
        { type: "CommentPublic", id: "COMMENT_LIST_PUBLIC" },
        { type: "CommentLogin", id: "COMMENT_LIST_LOGIN" },
        { type: "ReviewPublic", id: "REVIEW_LIST_PUBLIC" },
        { type: "ReviewLogin", id: "REVIEW_LIST_LOGIN" },
        { type: "ReviewLogin", id: "REVIEW_LIST_LOGIN" },
        { type: "LikedLogin", id: "LIKED_LOGIN" },
      ],
      // transformResponse(response: any, _) {
      //   return response;
      // },
    }),
    loginActiveCode: builder.mutation<LoginJson, loginACPostJson>({
      query: (body) => ({
        url: "users/customer/codelogin",
        method: "post",
        body: {
          platform: 1,
          hostname: Setting.REACT_APP_BASE_URL_HOST,
          version: "1.0.0",
        },
        params: {
          code: body.code,
        },
        keepalive: false,
      }),
    }),
    logout: builder.mutation({
      query: (body) => {
        return {
          url: "users/customer/logout",
          method: "post",
          // body,
          params: {},
        };
      },
      invalidatesTags: (result, error, body) => [
        { type: "CommentPublic", id: "COMMENT_LIST_PUBLIC" },
        { type: "CommentLogin", id: "COMMENT_LIST_LOGIN" },
        { type: "ReviewPublic", id: "REVIEW_LIST_PUBLIC" },
        { type: "ReviewLogin", id: "REVIEW_LIST_LOGIN" },
      ],
    }),
    signup: builder.mutation<SignupJson, SignupPostJson>({
      query: (body) => {
        return {
          url: "customers/register",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordJson,
      ForgotPasswordPostJson
    >({
      query: (body) => {
        // const convertBody = Helper.convertParams(body);
        return {
          url: "users/customer/forgotpassword",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    resetPassword: builder.mutation<any, ResetPasswordPostJson>({
      query: (body) => {
        return {
          url: "users/customer/resetpassword",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    verifyOtp: builder.mutation<VerifyJson, VerifyPostJson>({
      query: (body) => {
        return {
          url: "customers/public/verify",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    resend: builder.mutation<ForgotPasswordJson, ResendPostJson>({
      query: (body) => {
        return {
          url: "users/customer/resend",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    sender: builder.mutation<SenderJson, ResendPostJson>({
      query: (body) => {
        return {
          url: "customers/public/sender",
          method: "post",
          body: {
            ...body,
          },
        };
      },
    }),
    checkSender: builder.mutation<CheckSenderJson, ResendPostJson>({
      query: (body) => {
        return {
          url: "customers/public/checksender",
          method: "post",
          body: { ...body },
          params: {},
        };
      },
    }),
    setting: builder.query<SettingJson, string>({
      query: (params) => ({
        url: `settings/${params}`,
        method: "get",
        cache: "no-cache",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginActiveCodeMutation,
  useLogoutMutation,
  useSignupMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyOtpMutation,
  useResendMutation,
  useSenderMutation,
  useCheckSenderMutation,
  useSettingQuery,
  endpoints,
} = authApi;
