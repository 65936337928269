import { User } from "features/auth/types/auth.type";

const domain = "https://namperfume.net";
const Website = {
  ID: "nperp-features",
  FLOAT_BUTTON: {
    LIVE: 5,
    CONTACT: 3,
  },
  LOGIN: {
    HEADER_UNLOGIN: () => {
      return `<a href="javascript:void(0)">
        <img src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2553" alt="Account" class="icon" style="max-width: 80% !important;">
          <span class="text"> <a href="/pages/account">Đăng nhập</a> </span>
      </a>
      <div class="dropdown-menu box-account">
          <div class="info group-item">
            <p class="mb-0 icon"><img src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2553" alt="Account"></p>
            <p class="mb-0">
              <span class="title">Chào bạn, </span>
              <span class="text">Đăng nhập để tham gia với chúng tôi</span>
            </p>
          </div>
          <div class="actions group-item">
            <a href="/pages/account" class="btn signin">Đăng nhập</a>
            <a href="/pages/account/#/register" class="btn signup">Đăng ký</a>
          </div>
      </div>
    `;
    },

    HEADER_LOGIN: (user: User) => {
      return `<a href="javascript:void(0)">
        <img src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2555" alt="Account" class="icon" style="max-width: 80% !important;">
        
          <span class="text">Hi, <strong class="customer-name">
            ${user.full_name}
            </strong>
          </span>
        
      </a>
      <div class="dropdown-menu box-account">
      
        <div class="info group-item">
          <p class="mb-0 icon"><img src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2555" alt="Account"></p>
          <p class="mb-0">
            <span class="title">Chào bạn, </span>
            <span class="text"> ${user.full_name}
            </span>
          </p>
        </div>
        <ul class="group-item profile">
          <li><a href="/pages/account/#/me/myprofile">Thông tin tài khoản</a></li>
          <li class="last"><a href="/pages/account/#/logout">Đăng xuất</a></li>
        </ul>
      
      </div>
    `;
    },
    FOOTER_UNLOGIN_MOBILE: () => {
      return `<a id="account-login-no" href="/pages/account">
      <figure>
        <img class="turnOff" src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2553" alt="user">
        <img class="turnOn" src="//theme.hstatic.net/1000340570/1000964732/14/icon-user-fill.svg?v=2553" alt="user">
      </figure>
      <span class="text">Đăng nhập</span>
    </a>
      `;
    },
    FOOTER_LOGIN_MOBILE: () => {
      return `<a href="/pages/account/#/me/myprofile">
      <figure>
        <img class="turnOff" src="//theme.hstatic.net/1000340570/1000964732/14/icon-user.svg?v=2555" alt="user">
        <img class="turnOn" src="//theme.hstatic.net/1000340570/1000964732/14/icon-user-fill.svg?v=2555" alt="user">
      </figure>
      <span>Tôi</span>
    </a>
      `;
    },
  },
  LINK: {
    PAGE: {
      LOGIN: domain + "/pages/account",
      REWARD_VIEW: domain + "/pages/reward",
      // LOGIN: "/login",
      // REWARD_VIEW: "/me/myreward",
    },
  },
};

export default Website;
