import { BaseModelJson } from "common/interfaces/BaseModelJson";
import {
  CustomerJson,
  FilterCustomer,
  Rank,
} from "features/customer/types/customer.type";
import BaseModel from "./BaseModel";

interface Options {
  label: string;
  value: number;
}
class CustomerModel extends BaseModel implements BaseModelJson<CustomerJson> {
  company_id: number;
  creator_id: number;
  id: number;
  customer_type_id: number;
  code: string;
  full_name: string;
  first_name: string;
  last_name: string;
  gender: number;
  birthday: number;
  email: string;
  phone: string;
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  tag: string;
  note: string;
  invoicing_detail: string;
  count_order: number;
  point: number;
  money: number;
  date_created: number;
  date_modified: number;
  date_first_ordered: number;
  date_last_ordered: number;
  status: number;
  isdeleted: number;
  user_name: string;
  email_verified: number;
  phone_verified: number;
  date_deleted: number;
  datemodified_username: number;
  datemodified_contact: number;
  datemodified_email: number;
  datemodified_phone: number;
  rank: Rank;
  // event: Event;

  constructor(json: CustomerJson) {
    super();
    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.customer_type_id = json.customer_type_id || 0;
    this.code = json.code || "";
    this.full_name = json.full_name || "";
    this.first_name = json.first_name || "";
    this.last_name = json.last_name || "";
    this.gender = json.gender || 0;
    this.birthday = json.birthday || 0;
    this.email = json.email || "";
    this.phone = json.phone || "";
    this.address = json.address || "";
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.tag = json.tag || "";
    this.note = json.note || "";
    this.invoicing_detail = json.invoicing_detail || "";
    this.count_order = json.count_order || 0;
    this.point = json.point || 0;
    this.money = json.money || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_first_ordered = json.date_first_ordered || 0;
    this.date_last_ordered = json.date_last_ordered || 0;
    this.status = json.status || 0;
    this.isdeleted = json.isdeleted || 0;
    this.user_name = json.user_name || "";
    this.email_verified = json.email_verified || 0;
    this.phone_verified = json.phone_verified || 0;
    this.date_deleted = json.date_deleted || 0;
    this.datemodified_contact = json.datemodified_contact || 0;
    this.datemodified_username = json.datemodified_username || 0;
    this.datemodified_email = json.datemodified_email || 0;
    this.datemodified_phone = json.datemodified_phone || 0;
    this.rank = json.rank || {
      customer_id: 0,
      datecreated: 0,
      day_rank: 0,
      id: 0,
      total_rank_accumulate: 0,
      total_spending: 0,
      rank_type_id: 0,
      status: 0,
      max_day_rank: 0,
      min_rank_type: 0,
      max_rank_type: 0,
      rank_next: 0,
    };
    // this.event = json.event || {
    //   date_created: 0,
    //   rank_new: 0,
    //   rank_old: 0,
    //   event_detail_type: 0,
    //   event_type: 0,
    //   source_id: 0,
    //   source_type: 0,
    //   status: 0,
    // };
  }

  static getDefaultData(): CustomerJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      customer_type_id: 0,
      code: "",
      full_name: "",
      first_name: "",
      last_name: "",
      gender: 0,
      birthday: 1690614406,
      email: "",
      phone: "",
      address: "",
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      tag: "",
      note: "",
      invoicing_detail: "",
      count_order: 0,
      point: 0,
      money: 0,
      date_created: 0,
      date_modified: 0,
      date_first_ordered: 0,
      date_last_ordered: 0,
      status: 0,
      isdeleted: 0,
      email_verified: 0,
      phone_verified: 0,
      date_deleted: 0,
      user_name: "",
      datemodified_contact: 0,
      datemodified_username: 0,
      datemodified_phone: 0,
      datemodified_email: 0,
      rank: {
        customer_id: 0,
        datecreated: 0,
        day_rank: 0,
        id: 0,
        total_spending: 0,
        rank_type_id: 0,
        status: 0,
        total_rank_accumulate: 0,
        max_day_rank: 0,
        min_rank_type: 0,
        max_rank_type: 0,
        rank_next: 0,
      },
      // event: {
      //   date_created: 0,
      //   rank_new: 0,
      //   rank_old: 0,
      //   event_detail_type: 0,
      //   event_type: number,
      //   source_id: number,
      //   source_type: number,
      //   status: number,
      // },
    };
  }
  static getDefaultFilter(): FilterCustomer {
    return {
      page: 1,
      limit: 20,
      sort_by: "",
      sort_type: "DESC",
      keyword: "",
      status: 1,
    };
  }

  toJson(): CustomerJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      customer_type_id: this.customer_type_id,
      code: this.code,
      full_name: this.full_name,
      first_name: this.first_name,
      last_name: this.last_name,
      gender: this.gender,
      birthday: this.birthday,
      email: this.email,
      phone: this.phone,
      address: this.address,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      tag: this.tag,
      note: this.note,
      invoicing_detail: this.invoicing_detail,
      count_order: this.count_order,
      point: this.point,
      money: this.money,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_first_ordered: this.date_first_ordered,
      date_last_ordered: this.date_last_ordered,
      status: this.status,
      isdeleted: this.isdeleted,
      email_verified: this.isdeleted,
      phone_verified: this.isdeleted,
      date_deleted: this.isdeleted,
      user_name: this.user_name,
      datemodified_contact: this.datemodified_contact,
      datemodified_username: this.datemodified_username,
      datemodified_email: this.datemodified_email,
      datemodified_phone: this.datemodified_phone,
      rank: this.rank,
      // event: this.event,
    };
  }
  static getDate = (key: string) => {
    let day = [];
    switch (key) {
      case "day":
        for (let i = 1; i <= 31; i++) {
          day.push({
            value: i,
            text: i.toString(),
          });
        }
        return day;
      case "month":
        for (let i = 1; i <= 12; i++) {
          day.push({
            value: i,
            text: i.toString(),
          });
        }
        return day;
      case "year":
        for (let i = new Date().getFullYear(); i >= 1950; i--) {
          day.push({
            value: i,
            text: i.toString(),
          });
        }
        return day;
    }
  };

  static getGenders(): Options[] {
    return [
      { label: "Nữ", value: 1 },
      { label: "Nam", value: 3 },
      { label: "Khác", value: 5 },
      // { label: "Không rõ", value: 7 },
    ];
  }
}

export default CustomerModel;
