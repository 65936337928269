class Error {
  statusCode: number;
  errors: string[];

  constructor({
    statusCode,
    errors,
  }: {
    statusCode: number;
    errors: string[];
  }) {
    this.statusCode = statusCode;
    this.errors = errors;
  }
}

export default Error;
