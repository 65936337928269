import NTButton from "nteam/NTButton";
import React from "react";

type Props = {
  error: any;
  resetErrorBoundary: any;
};
export default function MyFallbackComponent({
  error,
  resetErrorBoundary,
}: Props) {
  return (
    <div
      role="alert"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: 24, color: "#d72229" }}>
        Có lỗi hệ thống vui thử lại !
      </p>
      {/* <pre>{error.message}</pre> */}
      <NTButton
        onClick={resetErrorBoundary}
        mode="main"
        label="Tải lại"
      ></NTButton>
    </div>
  );
}
