import NTButton from "nteam/NTButton";
import React from "react";
type Props = {
  type?: "page" | "component";
};
const PageNotFound = ({ type = "page" }: Props) => {
  const typeError = {
    page: (
      <div id="404" className="pageNot text-center wrap-flex-align">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="content-pagenot">
            <h1>Tính năng hiện chưa khả dụng</h1>
            <p style={{ textAlign: "center" }} className="subtext">
              Xin lỗi, bạn vui lòng tải lại trang nhé !
            </p>
            <button
              style={{
                cursor: "pointer",
                fontWeight: 500,
                border: "1px solid #3a393a",
                backgroundColor: "#3a393a",
                color: "#fff",
                padding: "0 6px",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Tải lại
            </button>
            <span>|</span>
            <a
              style={{ display: "inline-block" }}
              href="https://namperfume.net"
            >
              Trở về trang chủ
            </a>
          </div>
        </div>
      </div>
    ),
    component: <></>,
  }[type];
  return typeError;
};

export default PageNotFound;
