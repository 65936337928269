import StarIconDetail from "library/icons/StarIconDetail";
import React, { useMemo } from "react";

type Props = {
	stars: number;
	size?: "desktop" | "mobile";
};
const RateStarDetail = ({ stars, size = "desktop" }: Props) => {
	const styles = {
		optStar: {
			color: "sd",
		},
	};

	const value = useMemo(() => {
		let finalValue = stars;
		const r = stars % 1;
		if (r <= 0) {
			finalValue = stars;
		} else {
			finalValue = Math.floor(stars) + 0.5;
		}

		return finalValue;
	}, [stars]);

	//
	switch (value) {
		case 0:
			return (
				<>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 1:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 1.5:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="halffill"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 2:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 2.5:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="halffill"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 3:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 3.5:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="halffill"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 4:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
		case 4.5:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="halffill"></StarIconDetail>
				</>
			);
		case 5:
			return (
				<>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
					<StarIconDetail size="desktop" type="filed"></StarIconDetail>
				</>
			);
		default:
			return (
				<>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
					<StarIconDetail size="desktop" type="line"></StarIconDetail>
				</>
			);
	}
};

export default RateStarDetail;
