import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { LoginJson, User } from "./types/auth.type";
import { endpoints } from "./service/auth.service";
import { useCookies } from "react-cookie";

interface InititalState {
  token: string | null;
  showLogin: boolean;
  user: LoginJson | null;
  errors: string[];
  saveSignIn: boolean;
  password: string;
  callbackUrl: string;
}

// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number, thunkAPI) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

const userLocalStorage = localStorage.getItem("MAIN:user");
const initialState: InititalState = {
  // token: "eb5f94b88e72160cbe6525b6dc2dcccc",
  showLogin: false,
  token: null,
  user: userLocalStorage && JSON.parse(userLocalStorage),
  errors: [],
  saveSignIn: true,
  password: "",
  callbackUrl: "",
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("MAIN:token", JSON.stringify(action.payload));
    },
    clearToken: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("MAIN:token");
      localStorage.removeItem("MAIN:user");
    },
    toggleShowLogin: (state) => {
      state.showLogin = !state.showLogin;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("MAIN:user", JSON.stringify(action.payload));
    },

    setSaveSignInAuth: (state, action) => {
      state.saveSignIn = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    /////////////////////////////
    setErrorsAuth: (
      state,
      { payload: { errors } }: { payload: { errors: string[] } }
    ) => {
      state.errors = errors;
    },
    setCallbackUrl: (state, action) => {
      state.callbackUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(endpoints.login.matchFulfilled, (state, action) => {
      if (
        action.payload.hasOwnProperty("status") &&
        action.payload.status === "SUCCESS"
      ) {
        state.user = action.payload;
        state.token = action.payload.jwt;
      }
      if (
        action.payload.hasOwnProperty("status") &&
        action.payload.status === "SUCCESS" &&
        state.saveSignIn
      ) {
        localStorage.setItem("MAIN:token", JSON.stringify(action.payload.jwt));
        localStorage.setItem("MAIN:user", JSON.stringify(action.payload));
      }
    });
    builder.addMatcher(
      endpoints.loginActiveCode.matchFulfilled,
      (state, action) => {
        state.user = action.payload;
        state.token = action.payload.jwt;
        if (
          action.payload.hasOwnProperty("status") &&
          action.payload.status === "SUCCESS" &&
          state.saveSignIn
        ) {
          localStorage.setItem(
            "MAIN:token",
            JSON.stringify(action.payload.jwt)
          );
          localStorage.setItem("MAIN:user", JSON.stringify(action.payload));
        }
      }
    );
    builder.addMatcher(
      endpoints.logout.matchFulfilled || endpoints.logout.initiate,
      (state, action) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem("MAIN:token");
        localStorage.removeItem("MAIN:user");
      }
    );
  },
});

export const {
  setToken,
  toggleShowLogin,
  clearToken,
  setUser,
  setErrorsAuth,
  setSaveSignInAuth,
  setPassword,
  setCallbackUrl,
} = AuthSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectCallbackUrl = (state: RootState) => state.auth.callbackUrl;

export default AuthSlice.reducer;
