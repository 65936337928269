import React from "react";
import "../css/reward.scss";
const RewardTerm = () => {
  return (
    <div className="term">
      <h1>Xin chào Nam Nguyen, mọi thứ bạn cần biết về namperfume Reward.</h1>
      <div>
        <p>
          Các điều khoản và điều kiện xuất hiện trong bài viết này, cũng như bất
          kỳ vị trí nào bạn có thể bắt gặp, đều được xây dựng trên cơ sở pháp lý
          tại namperfume. Lưu ý, việc bạn tham gia namperfume Reward tương đương
          với việc bạn đồng ý sự ràng buộc bởi các điều khoản và điều kiện này.
          Chúng tôi xin được chú giải về danh xưng: - “Bạn” hoặc “Của bạn”: Đề
          cập đến bạn, người tham gia vào namperfume Reward. - “Tôi” hoặc “Của
          chúng tôi”: Đề cập đến namperfume, công ty sở hữu namperfume Reward.
          Các điều khoản và điều kiện này liên quan đến điều kiện và điều khoản
          của website namperfume, cũng như các chính sách về cookie và quyền
          riêng tư của chúng tôi. Bất kỳ tên miền phụ nào và bất kỳ trang web
          nào khác do chúng tôi hoặc người thay mặt chúng tôi điều hành và bất
          kỳ ứng dụng thiết bị di động hoặc ứng dụng máy tính để bàn nào do
          chúng tôi hoặc người thay mặt chúng tôi phát triển (cùng với 'trang
          web' và ' website' là một tham chiếu đến bất kỳ một trong số chúng).
        </p>
        <div>
          <h2>1) Tham gia namperfume Reward</h2>
          <p>
            Bạn phải từ 16 tuổi trở lên, đủ điều kiện mua hàng tại namperfume.
            Ngay khi mua thành công đơn hàng đầu tiên, bạn sẽ được ghi nhận là
            khách hàng thân thiết trong chương trình namperfume Reward. Mỗi
            khách hàng thân thiết được ghi nhận thông qua số điện thoại thành
            viên, liên kết với 1 tài khoản website namperfume. Chúng tôi không
            chịu trách nhiệm cho các trường hợp liên kết nhiều tài khoản, hoặc
            ghi nhận 1 số điện thoại cho nhiều khách hàng khác nhau. Do đó, mỗi
            khách hàng sẽ được đo lường chi tiêu khác nhau, cho việc ghi nhận
            hạng thành viên. "Kiểm tra trang tổng quan Quyền truy cập của bạn để
            biết thông tin cập nhật về cấp độ và phần thưởng của bạn. Bạn có thể
            tìm thấy trang tổng quan Quyền truy cập trong tài khoản namperfume
            của mình. Hãy đảm bảo rằng bạn đã đăng ký nhận thông báo đẩy và
            email của chúng tôi để chúng tôi có thể gửi cho bạn thông tin cập
            nhật về tư cách thành viên thân thiết của bạn, khi tham gia
            namperfume Reward. Bạn có thể kiểm tra và cập nhật tùy chọn liên lạc
            của bạn trong tài khoản namperfume của bạn."
          </p>
        </div>
        <div>
          <h2>2) Điều kiện mua hàng</h2>
          <p>
            Ngay khi bạn tạo thành công tài khoản namperfume, bạn sẽ được ghi
            nhận ở mức Member. Nhưng, chỉ khi bạn thành công mua đơn hàng đầu
            tiên, bạn mới thực sự tham gia vào namperfume Reward. Đơn vị đo
            lường hạng thành viên của bạn, là khả năng chi tiêu của bạn tại
            namperfume. Mọi giao dịch sẽ đóng góp vào tổng chi tiêu tích lũy của
            bạn, là cơ sở để thăng hạng thành viên. Chúng tôi có quyền quyết
            định những đơn hàng nào đạt trạng thái “thành công”. Đơn hàng thành
            công là đơn hàng không xảy ra trường hợp hoàn trả hoặc hủy bỏ. Đối
            với các đơn hàng không thành công, chúng tôi sẽ trực tiếp xử lý vào
            ghi nhận hạng thành viên của bạn
          </p>
        </div>
        <div>
          <h2>3) Hạng thành viên</h2>
          <p>
            Hạng thành viên là các bậc xếp hạng khách hàng khi tham gia
            namperfume Reward. namperfume Reward có 4 hạng thành viên: Member,
            Gold, Platinum và Niche. Khoảng thời gian từ khi bắt đầu một hạng
            thành viên mới, cho đến khi kết thúc, được ghi nhận là 1 năm thành
            viên. Sau khi kết thúc năm thành viên, bạn sẽ được duy trì hạng hiện
            tại, nâng cấp hạng cao hơn, hoặc đặt lại về hạng cơ sở (hạng Member)
            khi không đạt đủ điều kiện. Việc quy định các điều kiện sẽ thuộc
            toàn quyền của namperfume. Khách hàng sẽ được chi tiêu vô hạn cho
            các mục tiêu đạt hạng. Mỗi hạng thành viên sẽ tương ứng với những
            quà tặng hấp dẫn riêng biệt. Việc đạt hạng càng cao, sẽ mang đến cho
            bạn càng nhiều ưu đãi. Tuy nhiên, có một số quà tặng bị ràng buộc
            bởi chính sách của namperfume. Tổng chi tiêu được tính toán dựa trên
            khả năng chi tiêu của bạn. Một số tính toán nhất định có thể chịu sự
            biến động của tỷ giá hối đoái, được áp dụng theo quyết định riêng
            của namperfume. Giá trị namperfume Reward không thể được chuyển sang
            tài khoản của khách hàng khác.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RewardTerm;
