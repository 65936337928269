import Error from "common/api/Error";

class BaseModel {
  error: Error;

  constructor() {
    this.error = new Error({
      statusCode: 0,
      errors: [],
    });
  }

  withError(error: Error) {
    this.error = error;
  }

  hasError(): boolean {
    return this.error.errors.length > 0;
  }

  // static convertLocaleToLanguage(locale: string): string {
  //   let language = "";

  //   switch (locale) {
  //     case "vi-VN":
  //       language = "vn";
  //       break;
  //     case "en-US":
  //       language = "en";
  //       break;
  //     default:
  //       language = locale;
  //   }

  //   return language;
  // }
}

export default BaseModel;
