import comment from "./data/comment.json";
import file from "./data/file.json";
import customer from "./data/customer.json";
import newsletter from "./data/newsletter.json";

const resource = {
  comment,
  file,
  customer,
  newsletter,
};

export default resource;
