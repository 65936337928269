import React, { useMemo } from "react";
type Props = {
  mode?: "dark" | "light" | "main";
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  label: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  wrapperStyle?: React.CSSProperties;
  className?: string;
};
const MainButton = ({
  mode = "light",
  onClick,
  type = "submit",
  label,
  style,
  disabled,
  wrapperStyle,
  className,
}: Props) => {
  const modeButton = useMemo(() => {
    switch (mode) {
      case "dark":
        return "button_dark";
        break;
      case "main":
        return "button_main";
        break;
      case "light":
        return "button_light";
        break;
      default:
        return "button_light";
        break;
    }
  }, [mode]);
  return (
    <div style={wrapperStyle} className="main_button_wrapper">
      <button
        style={style}
        type={type}
        onClick={onClick}
        className={`main_button_wrapper_button ${modeButton} ${className}`}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
};

export default MainButton;
