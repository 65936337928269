import { lazy } from "react";

export const Customer = lazy(() => import("features/customer/Customer"));
export const CustomerView = lazy(
  () => import("features/customer/components/customerView/CustomerView")
);
export const CustomerProfile = lazy(
  () => import("features/customer/components/customerView/CustomerProfile")
);
export const CustomerResetForm = lazy(
  () => import("features/customer/components/customerView/CustomerResetForm")
);
export const CouponList = lazy(
  () => import("features/coupon/components/list/CouponList")
);
export const AddressView = lazy(
  () => import("features/customer/components/customerView/AddressView")
);
export const CustomerAddressList = lazy(
  () =>
    import(
      "features/customer/components/customerAddress/addressList/CustomerAddressList"
    )
);
export const CustomerAddressForm = lazy(
  () =>
    import(
      "features/customer/components/customerAddress/addressForm/CustomerAddressForm"
    )
);
export const CustomerMenu = lazy(
  () => import("features/customer/components/customerView/CustomerMenu")
);
export const NewLetter = lazy(() => import("features/newsletter/NewLetter"));
export const NLLetter = lazy(
  () => import("features/newsletter/components/NLLetter")
);
export const NLFeedback = lazy(
  () => import("features/newsletter/components/NLFeedback")
);
export const FeedbackSuccess = lazy(
  () => import("features/newsletter/components/FeedbackSuccess")
);
export const NLContact = lazy(
  () => import("./../features/newsletter/components/NLContact")
);
export const Logout = lazy(() => import("features/auth/pages/Logout"));
export const MainTest = lazy(() => import("components/MainTest"));
export const CustomerReward = lazy(
  () => import("features/customer/pages/CustomerReward")
);
export const Auth = lazy(() => import("features/auth/Auth"));
export const Login = lazy(() => import("features/auth/pages/Login"));
export const Register = lazy(() => import("features/auth/pages/Register"));
export const VerifyOTP = lazy(() => import("features/auth/pages/VerifyOTP"));
export const ForgotPassword = lazy(
  () => import("features/auth/pages/ForgotPassword")
);
export const ResetPassword = lazy(
  () => import("features/auth/pages/ResetPassword")
);
export const Comment = lazy(() => import("features/comment/Comment"));
export const UpdateCustomer = lazy(
  () => import("features/customer/pages/UpdateCustomer")
);
export const CustomerConfirmPassword = lazy(
  () => import("features/customer/pages/CustomerConfirmPassword")
);
export const CustomerEditEmail = lazy(
  () => import("features/customer/pages/CustomerEditEmail")
);
export const CustomerVerify = lazy(
  () => import("features/customer/pages/CustomerVerify")
);
export const CustomerEditPhone = lazy(
  () => import("features/customer/pages/CustomerEditPhone")
);
export const CustomerResetPassword = lazy(
  () => import("features/customer/pages/CustomerResetPassword")
);
export const CustomerEditUsername = lazy(
  () => import("features/customer/pages/CustomerEditUsername")
);

export const MyReward = lazy(
  () => import("features/customer/components/customerReward/MyReward")
);
