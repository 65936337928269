import { createSlice } from "@reduxjs/toolkit";
import { endpoints } from "./service/customer.service";
import { LoginJson, User } from "features/auth/types/auth.type";
import { useAppDispatch } from "app/hooks";
import { setUser } from "features/auth/AuthSlice";
import { CustomerJson } from "./types/customer.type";
import CustomerModel from "common/models/CustomerModel";
// import { fetchCount } from "./counterAPI";

export interface CustomerState {
  formType: string;
  isShowPopup: boolean;
  fullname: string;
  customerDetail: CustomerJson;
}

const initialState: CustomerState = {
  formType: "view",
  isShowPopup: false,
  fullname: "",
  customerDetail: CustomerModel.getDefaultData(),
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number, thunkAPI) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFormType: (state, action) => {
      state.formType = action.payload;
    },
    setEditType: (state, action) => {
      state.formType = action.payload;
    },
    setIsShowPopup: (state, action) => {
      state.isShowPopup = action.payload;
    },
    setFullname: (state, action) => {
      state.fullname = action.payload;
    },
    setCustomerDetail: (state, action) => {
      state.customerDetail = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      endpoints.editCustomerLogin.matchFulfilled,
      (state, action) => {
        state.fullname = action.payload.full_name;
        const userLocal = localStorage.getItem("MAIN:user");
        if (userLocal) {
          let userParse: LoginJson = JSON.parse(userLocal);
          userParse.user.full_name = action.payload.full_name;
          localStorage.setItem("MAIN:user", JSON.stringify(userParse));
        }
      }
    );
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.value += action.payload;
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = "failed";
  //     });
  // },
});
// export const selectLCurrentFormType= (state: RootState) => state.;

export const {
  setFormType,
  setEditType,
  setIsShowPopup,
  setFullname,
  setCustomerDetail,
} = CustomerSlice.actions;

export default CustomerSlice.reducer;
