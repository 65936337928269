import React from "react";
import { store } from "app/store";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

type Element = {
  node: React.ReactNode;
  id: string;
};
type Props = {
  elements: Element[];
};
const useRender = () => {
  const renderElement = ({ elements }: Props) => {
    const body = document.body;
    if (elements.length > 0) {
      elements.forEach((elements) => {
        const container = document.createElement("div");
        container.id = elements.id;
        body.appendChild(container);
        if (container) {
          const root = createRoot(container);
          root.render(<Provider store={store}>{elements.node}</Provider>);
        }
      });
    }
  };

  return {
    renderElement,
  };
};

export default useRender;
