class Setting {
  static isBeta() {
    const pathNameText = window.location.href;

    if (pathNameText) {
      if (pathNameText.includes("products")) {
        return true;
      }
      return false;
    }
    console.log(process.env.NODE_ENV);
    return (
      // window.location.host.lastIndexOf("localhost") >= 0 ||
      window.location.host.lastIndexOf("beme.vn") >= 0 ||
      window.location.host.lastIndexOf("namperfume.net") >= 0
    );
  }

  static get REACT_APP_BASE_URL() {
    // if (this.isBeta()) {
    //   return "http://nperp-api.namperfume.ldev/v1";
    // } else {
    //   return "https://nperp-api.namperfume.ldev/v1";
    // }

    return process.env.REACT_APP_BASE_URL;
  }

  static get REACT_APP_BASE_URL_HOST() {
    // if (this.isBeta()) {
    //   return "devnteam.beme.vn";
    // } else {
    //   return "admin.namperfume.ldev";
    // }

    return process.env.REACT_APP_BASE_URL_HOST || "admin.namperfume.ldev";
  }

  static get REACT_APP_BASE_URL_ASSET() {
    // if (this.isBeta()) {
    //   return "https://cross.namefragrance.vn/imgs";
    // } else {
    //   // return "http://nperp.namperfume.ldev/imgs";
    //   return "https://cross.namefragrance.vn/imgs";
    // }
    return process.env.REACT_APP_BASE_URL_ASSET;
  }

  // https://cross.namefragrance.vn/imgs

  static get REACT_APP_IMAGINARY_BASE_URL() {
    // if (this.isBeta()) {
    //   //return "https://beta-nt-thumbnail.hada.vn";
    //   return "https://api.namefragrance.vn/v1";
    // } else {
    //   //return "https://nt-thumbnail.hada.vn";
    //   return "http://nperp-thumbnail.namperfume.ldev/v1";
    //   // return "https://api.namefragrance.vn/v1";
    // }
    return process.env.REACT_APP_IMAGINARY_BASE_URL;
  }

  static get REACT_APP_PUSHER_KEY() {
    if (this.isBeta()) {
      return "5c9399e70d07db776d27";
    } else {
      return "5c9399e70d07db776d27";
    }
  }

  static get REACT_APP_PUSHER_CLUSTER() {
    if (this.isBeta()) {
      return "mt1";
    } else {
      return "mt1";
    }
  }
}

export default Setting;
