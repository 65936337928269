import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  floatButtonVisile: number;
};
const initialState: InitialState = {
  floatButtonVisile: 0,
};
export const WebsiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    setFloatButtonVisible: (state, action) => {
      state.floatButtonVisile = action.payload;
    },
  },
});

export const { setFloatButtonVisible } = WebsiteSlice.actions;

export default WebsiteSlice.reducer;
