import moment, { Moment } from "moment";
import dayjs from "dayjs";
class Helper {
  static moneyFormat(value: number): string {
    let output = "";

    //languagecode-countrycode:
    //languagecode: full list @ https://www.w3schools.com/tags/ref_language_codes.asp
    //countrycode: full list @ https://www.w3schools.com/tags/ref_country_codes.asp
    const locale = "vi-VN";

    //full currencylist at https://www.currency-iso.org/en/home/tables/table-a1.html
    const currency = "VND";

    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });

    //formating data
    output = formatter.format(value);

    return output;
  }
  static moneyShortFormat(value: number, label: string): React.ReactNode {
    return (
      <span>
        {Number(value.toString().replace(/0\d/g, ""))} {label}
      </span>
    );
  }
  static numberFormat(value: number): string {
    let output = "";

    const locale = "vi-VN";
    const formatter = new Intl.NumberFormat(locale);

    //formating data
    output = formatter.format(value);

    return output;
  }

  static round(number: number, precision: number) {
    var shift = (number: number, precision: number) => {
      var numArray = ("" + number).split("e");
      return +(
        numArray[0] +
        "e" +
        (numArray[1] ? +numArray[1] + precision : precision)
      );
    };
    return shift(Math.round(shift(number, +precision)), -precision);
  }

  static isNumber(n: string) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  static fileExtension(filename: string) {
    if (!filename) return "";
    var ext = (/[^./\\]*$/.exec(filename) || [""])[0];
    return ext.toLowerCase();
  }

  static codau2khongdau(str: string): string {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D");
  }

  static checkDateString(date: number) {
    return date.toString().length < 8 ? "0" + date.toString() : date.toString();
  }

  static checkTimeString(time: number) {
    return time.toString().length < 4 ? "0" + time.toString() : time.toString();
  }

  static getHostname(): string {
    //this values will detect as NO-HOSTNAME
    //and trigger show hostname input on login / forgotpass / reset pass
    const markAsEmptyHostnameItems = [
      "localhost",
      "app.cropany.com",
      "betaapp.cropany.com",
      "nperp.namperfume.ldev",
    ];
    const hostname = document.location.hostname || "";
    if (hostname === "" || markAsEmptyHostnameItems.includes(hostname)) {
      return "admin.namperfume.ldev";
    } else {
      return hostname;
    }
  }

  static isJsonParsable(text: string): boolean {
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  }

  static convertedDateToTimeStamp = (myDate: Moment): number => {
    const timestamp = myDate ? Math.round(myDate.valueOf() / 1000) : -1;

    return timestamp;
  };

  // static convertedDateToTimeStampDayjs = (myDate: any): number => {
  //   const timestamp = new Date(moment(myDate).format("DD-MM-YYYY")).getTime();

  //   return timestamp;
  // };

  static datePickerValueToTimestamp(datePickerValue: any) {
    // Check if the datePickerValue is valid and not null or undefined

    // Parse the selected date from the DatePicker value (assuming the format is 'YYYY-MM-DD')
    const selectedDate = dayjs(datePickerValue);

    // Get the timestamp in milliseconds from the Day.js object
    const timestamp = selectedDate.unix();

    return timestamp;
  }

  static convertedTimestampToDate = (timestamp: number, showtime?: boolean) => {
    const showTime = typeof showtime !== "undefined" ? showtime : true;
    const time = `${new Date(timestamp * 1000).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })} ,`;
    const date = new Date(timestamp * 1000).toLocaleDateString("en-GB");
    if (showTime) {
      return time + date;
    }
    return date;
  };

  static splitFullName(fullname: string) {
    // Tách chuỗi fullname thành mảng các từ
    const words = fullname.split(" ");

    // Lấy phần tử cuối cùng trong mảng là firstname
    const firstname = words.pop();

    // Gắn lại các phần tử còn lại là lastname
    const lastname = words.join(" ");

    // Trả về object chứa lastname và firstname
    return { lastname, firstname };
  }

  static validateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  static validatePhoneNumber(phone: string) {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    return phone.match(regexPhoneNumber) ? true : false;
  }

  static convertParams(params: any): any {
    let newParam = { ...params };
    for (const prop in newParam) {
      if (newParam[prop] === null || newParam[prop] === undefined) {
        delete newParam[prop];
      }
    }
    return newParam;
  }
  static isMobile = () => {
    if (typeof (window !== undefined)) {
      let widthDevice = window.innerWidth;
      const setWidthDevice = () => {
        widthDevice = window.innerWidth;
      };
      window.addEventListener("resize", setWidthDevice);
      if (widthDevice <= 768) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
}

export default Helper;
