import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";

import { SettingJson } from "features/auth/types/auth.type";

class SettingModel extends BaseModel implements BaseModelJson<SettingJson> {
  key: string;
  value: string;
  id: number;

  constructor(json: SettingJson) {
    super();
    this.key = json.key || "";
    this.value = json.value || "";
    this.id = json.id || 0;
  }

  static getDefaultData(): SettingJson {
    return {
      key: "",
      value: "",
      id: 0,
    };
  }

  toJson(): SettingJson {
    return {
      key: this.key,
      value: this.value,
      id: this.id,
    };
  }
  hasError() {
    return (
      this.error != null &&
      this.error.errors != null &&
      this.error.errors.length > 0
    );
  }
}

export default SettingModel;
