import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./styles.css";
import "./helper.css";
import "./features.scss";

import AppWithLocale from "./AppWithLocale";

const container = document.getElementById("nperp-features")!;

const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <AppWithLocale>
    <Provider store={store}>
      <App></App>
    </Provider>
  </AppWithLocale>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
